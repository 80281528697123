import {
    CancelTransferPayload,
    ConfirmRepaymentPayload,
    GetDfasParams,
    IApprovalHistoryDfa,
    ICouponPaymentPayload,
    ICursorPaginationResponse,
    IDFAOwnershipTransferRequest,
    IDFAOwnershipTransferRequestClient,
    IDfaAvailable,
    IDfaAvailablePrice,
    IDfaBack,
    IDfaLink,
    IEmissionDfaPayload,
    IIssueStopRequest,
    IIssueUnStopRequest,
    IMakeIssueFailed,
    IRequest,
    ITransferRejectRequest,
    ParamsForDfaDeals,
    PayoffAgreementsType,
    PostDfaRepaymentPayload,
    PostRepaymentByOperatorType,
    PutDfaRepaymentPayload,
    PutRepaymentByOperatorType,
    RepaymentMatureByOperatorType,
    ResponseItems,
    SignTransactionFullType,
    SignTransactionShortType,
    TablePaginationParam,
    UnionDealType,
} from '@dltru/dfa-models'

import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

import { requestDecorator } from '..'

export async function getDfaList(
    params: GetDfasParams,
): Promise<IRequest<ICursorPaginationResponse<IDfaBack> | null>> {
    return requestDecorator.call(this, this.paths.Dfa, HttpMethod.GET, HttpCode.SUCCESS_200, {
        params,
    })
}

export async function storeDfa(dfa: IDfaBack): Promise<IRequest<ResponseItem<IDfaBack>>> {
    return requestDecorator.call(this, this.paths.DfaV2, HttpMethod.POST, HttpCode.SUCCESS_200, {
        data: dfa,
    })
}

export async function getDfaById(dfaId: number): Promise<IRequest<IDfaBack>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaById, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getDfaDealById(dfaDealId: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaDealsByDealId, dfaDealId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function patchDfaLink(id: string, payload: IDfaLink): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaEntryEmitterLink, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function getDfaAvailableDeals(
    dfaId: number,
): Promise<IRequest<ResponseItem<IDfaAvailable>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaAvailable, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getDfaAvailableDealsV2(
    dfaId: number,
): Promise<IRequest<ResponseItem<IDfaAvailablePrice>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaAvailableV2, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function patchDfaDevEmission(
    payload: IEmissionDfaPayload,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaIssue, payload.asset_id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { skid: payload.skid } },
    )
}

export async function putDfaDevEmission(
    data: SignTransactionShortType,
    assetId: number,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaIssue, assetId),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        {
            data: { transaction: data },
        },
    )
}

export async function postDfaRepaymentPayload(
    data: PostDfaRepaymentPayload,
): Promise<IRequest<SignTransactionFullType>> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaRepaymentV4,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function putDfaRepaymentPayload(data: PutDfaRepaymentPayload): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaRepaymentV4,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getDfaDealsByDfaId<T = { items: UnionDealType[] }>(
    dfaId: string,
): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaDealsById, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getSecondaryDeals<T>(params?: ParamsForDfaDeals): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaDeals,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getRepaymentDeals<T>(params?: ParamsForDfaDeals): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaDeals,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function createDFAOwnershipTransferRequestV3(
    data: IDFAOwnershipTransferRequestClient,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderInitV3,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putDFAOwnershipTransferRequestV3(
    data: IDFAOwnershipTransferRequestClient & { transaction: SignTransactionFullType },
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderInitV3,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function createDFAOwnershipTransferRequestByOperatorV2(
    data: IDFAOwnershipTransferRequest,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaDealByOperatorV2,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function acceptDFAOwnershipTransferRequest<T>(order_id: number): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderAccept,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { order_id } },
    )
}

export async function acceptDFAOwnershipTransferRequestV2<T>(data: {
    external_deal_id: number
    skid: string
}): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderAcceptV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function executeDFATransferServiceV2<T>(data: {
    external_deal_id: string
    skid: string
}): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderExecuteV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { skid: data.skid, external_deal_uuid: data.external_deal_id } },
    )
}

export async function transferDfaCancelByOperatorServiceV2<T>(data: {
    external_deal_id: number
    skid: string
}): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderCancelByOperatorV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getDFAOwnershipTransferRequest<T>(orderId: number): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        path(this.paths.TransferDfaOrderByOrderId, orderId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getDFAOwnershipTransferByGlobalIdRequest<T>(
    globalId: string,
): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        path(this.paths.TransferDfaOrdersByGlobalId, globalId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function updateDfaService(dfaId: number, dfa: IDfaBack): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaById, dfaId),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data: { ...dfa } },
    )
}

export async function rejectDfaService(
    id: number,
    data: Omit<IMakeIssueFailed, 'dfaId'>,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaReject, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function rejectDfaServiceV2(
    assetId: number,
    data: { reason: string },
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaRejectV2, assetId),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function issuerConfirmationDfaService(assetId: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaIssuerConfirmation, assetId),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}

// TODO удалить, если не потребуется
export async function approveDfaService<T>(
    id: string,
    payload: { operator_id: string },
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaConfirm, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function patchDfaLinkService<T>(id: string, payload: IDfaLink): Promise<IRequest<T>> {
    try {
        let endpoint = 'emitter_link'
        if (payload.ois_link) {
            endpoint = 'ois_link'
        }

        const { data, status } = (await this.requestAgent({
            method: HttpMethod.PATCH,
            url: path(this.paths.DfaEntry, id, endpoint),
            data: payload,
        })) as { data: T; status: number }

        if (status !== HttpCode.SUCCESS_200) {
            throw new Error('error')
        }

        return { data, error: undefined }
    } catch (error) {
        return { error }
    }
}

export async function confirmDfaService(id: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaConfirmSecondary, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}
export async function confirmToLawyerDfaService(id: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaConfirmToLawyer, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}
export async function confirmToTellerDfaService(id: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaConfirmToTeller, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}
export async function confirmToSpecDfaService(id: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaConfirmToSpec, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}
export async function toModificationDfaService(
    assetId: string,
    data: { reason: string },
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaToModification, assetId),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function toCollectionStartedDfaService(id: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaToCollectionStarted, id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}

export async function getDfaOwnersService<T>(
    dfaId: string,
    page: number,
    limit: number,
    sort_by: string,
    order_by: string,
): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletDfaOwners, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function checkTicker(ticker: string): Promise<IRequest<ResponseItem<boolean>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaCheckTicker, ticker),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function repaymentDfaConfirmByInvestorServiceV2(
    data: ConfirmRepaymentPayload,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaConfirmByInvestorV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function emitmentStopByOperatorService<T>({
    asset_id,
    issue_stop_reason,
    issue_stop_reason_documents_uuid,
}: IIssueStopRequest): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmitmentStop, asset_id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { issue_stop_reason, issue_stop_reason_documents_uuid } },
    )
}

export async function emitmentUnstopByOperatorService<T>({
    asset_id,
    issue_unstop_reason,
    issue_unstop_reason_documents_uuid,
}: IIssueUnStopRequest): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmitmentUnstop, asset_id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { issue_unstop_reason, issue_unstop_reason_documents_uuid } },
    )
}

export async function makeDFAIssueFailed({ dfaId, ...data }: IMakeIssueFailed): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaMakeIssueFailed, dfaId),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function emitmentRevokeService(asset_id: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmitmentRevoke, asset_id),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}

export async function transferCancelByOwnerServiceV2(
    data: CancelTransferPayload,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferCancelByOfferorV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function transferRejectService<T>({
    uuid,
    cancel_reason,
}: ITransferRejectRequest): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.TransferReject, uuid),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { cancel_reason } },
    )
}

export async function transferCancelByRecipientService<T>(uuid: number): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.TransferCancelByRecipient, uuid),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
    )
}
type GetPayoffAgreementsParamsType = TablePaginationParam & { asset_id?: number }
export async function getPayoffAgreements(
    params: GetPayoffAgreementsParamsType,
): Promise<IRequest<ResponseItem<ICursorPaginationResponse<PayoffAgreementsType> | null>>> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaPayoffAgreements,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function postRepaymentByOperator(
    data: PostRepaymentByOperatorType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaByOperator,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putRepaymentByOperator(data: PutRepaymentByOperatorType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaByOperator,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function repaymentMatureInvestor(
    data: RepaymentMatureByOperatorType,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentMatureInvestor,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function repaymentMatureAsset(data: RepaymentMatureByOperatorType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentMatureAsset,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getCountDfa(params: {
    statuses: string
}): Promise<IRequest<ResponseItem<number>>> {
    return requestDecorator.call(
        this,
        this.paths.DfaCounter,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getApprovalHistoryDfa(
    assetId: number,
): Promise<IRequest<ResponseItems<IApprovalHistoryDfa>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaApprovalHistoryByDfaId, assetId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
export async function getApprovalHistoryDfaForAdmin(
    assetId: number,
): Promise<IRequest<ResponseItems<IApprovalHistoryDfa>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaApprovalHistoryForAdmin, assetId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function patchRedeemDateByDfaId(
    assetId: number,
    redeem_date: number,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaPatchRedeemDate, assetId),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { redeem_date } },
    )
}

export async function sendDfaCouponPayment(data: ICouponPaymentPayload): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.DfaCouponPayment,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
