import {
    BlockEmployeeData,
    EditEmployeeType,
    GetEmployeesParams,
    ICreateEmployee,
    ICursorPaginationResponse,
    IEmployee,
    IRequest,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

type ChangeAuthAdminResponse = IRequest<ResponseItem<{ phone_last_four_digits: string }>>

export async function changePhoneInitAdmin(user_uuid: string): Promise<ChangeAuthAdminResponse> {
    return requestDecorator.call(
        this,
        this.paths.EmployeeChangePhoneInitAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}

export async function changePasswordInitAdmin(user_uuid: string): Promise<ChangeAuthAdminResponse> {
    return requestDecorator.call(
        this,
        this.paths.EmployeeChangePasswordInitAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}

export async function getEmployees(
    params?: GetEmployeesParams,
): Promise<IRequest<ICursorPaginationResponse<IEmployee>>> {
    return requestDecorator.call(this, this.paths.Employee, HttpMethod.GET, HttpCode.SUCCESS_200, {
        params,
    })
}

export async function getEmployeeById(
    user_uuid: string,
): Promise<IRequest<ICursorPaginationResponse<IEmployee>>> {
    return requestDecorator.call(this, this.paths.Employee, HttpMethod.GET, HttpCode.SUCCESS_200, {
        params: { user_uuid },
    })
}

export async function postEmployee(data: ICreateEmployee): Promise<IRequest> {
    return requestDecorator.call(this, this.paths.Employee, HttpMethod.POST, HttpCode.SUCCESS_200, {
        data,
    })
}
export async function updateEmployee(user_uuid: string, data: EditEmployeeType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmployeeEdit, user_uuid),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function blockEmployee(user_uuid: string, data: BlockEmployeeData): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmployeeBlock, user_uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function unBlockEmployee(
    user_uuid: string,
    data: BlockEmployeeData,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmployeeUnBlock, user_uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function archiveEmployee(user_uuid: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EmployeeArchive, user_uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: {} },
    )
}
