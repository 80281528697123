import {
    ICursorPaginationResponse,
    IEarlyRepayment,
    IEarlyRepaymentBuIssuerResponse,
    IEarlyRepaymentFeeRequest,
    IEarlyRepaymentFeeResponse,
    IEarlyRepaymentForcePostRequest,
    IEarlyRepaymentForcePostResponse,
    IEarlyRepaymentForcePutRequest,
    IEarlyRepaymentItem,
    IEarlyRepaymentPostReques,
    IEarlyRepaymentPostResponse,
    IEarlyRepaymentRquest,
    IRequest,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function postEarlyRepaymentApplicationService(
    data: IEarlyRepaymentPostReques,
): Promise<IRequest<IEarlyRepaymentPostResponse>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentrequest),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getEarlyRepaymentApplicationsService(
    params: Partial<IEarlyRepaymentItem>,
): Promise<IRequest<ICursorPaginationResponse<IEarlyRepaymentItem>>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentrequest),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function postEarlyRepaymentConfirmByIssuerService(
    data: IEarlyRepaymentRquest,
): Promise<IRequest<IEarlyRepaymentPostResponse>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentConfirmByIssuer),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putEarlyRepaymentConfirmByIssuerService(
    data: IEarlyRepaymentBuIssuerResponse,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentConfirmByIssuer),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getEarlyRepaymentFeeService(
    params: IEarlyRepaymentFeeResponse,
): Promise<IRequest<IEarlyRepaymentFeeRequest>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentCalculateFee),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function postEarlyRepaymentConfirmByInvestorService(
    data: IEarlyRepaymentRquest,
): Promise<IRequest<IEarlyRepaymentPostResponse>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentConfirmByInvestor),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getEarlyRepaymentByUuidService(
    uuid: string,
): Promise<IRequest<IEarlyRepayment>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentRequestByUuid, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

// admin
export async function postEarlyRepaymentForceService(
    data: IEarlyRepaymentForcePostResponse,
): Promise<IRequest<IEarlyRepaymentForcePostRequest>> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentOperatorRepayment),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

// admin
export async function putEarlyRepaymentForceService(
    data: IEarlyRepaymentForcePutRequest,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentOperatorRepayment),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getEarlyRepaymentEntryRedeemDateService(id: string): Promise<
    IRequest<{
        redeem_date: number
    }>
> {
    return requestDecorator.call(
        this,
        path(this.paths.EarlyRepaymentEntryRedeemDate, id),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
